import React from "react"
import { Helmet } from "react-helmet"
import OGP from "@images/ogp.png"

export default props => { 
    const baseTitle = "KnockLearn(ノックラーン) | 副業社会人によるオンライン家庭教師";
    const title = props.title ? `${props.title} | ${baseTitle}` : baseTitle
    return (
      <Helmet>
        <html lang="ja" />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{title}</title>
        <meta name="description" content={props.desc} />
        <meta name="keywords" content="KnockLearn(ノックラーン)" />
        <meta property="og:site_name" content="KnockLearn(ノックラーン) | 教育の力で次世代へバトンを。"/>
        <meta property="og:description" content="学生の社会性を育む、オンライン家庭教師サービス「KnockLearn(ノックラーン)」。KnockLearnでは「副業社会人講師」が教科学習や社会教育を<br/>お子様向けに教えることで社会性を育むオンライン家庭教師を提供しています。"/>
        <meta property="og:title" content="KnockLearn(ノックラーン) | 教育の力で次世代へバトンを。"/>
        <meta property="og:image" content={OGP}/>
        <meta name="thumbnail" content={OGP} />
        <meta property="og:locale" content="ja_JP"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content={OGP} />
        <meta name="twitter:site" content="@KnockLearn" />
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:image" content={OGP}/>
      </Helmet>
    )
  }