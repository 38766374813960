import React,{useState} from "react"
import { Link } from "gatsby"
import '@styles/components/menu-trigger.scss'
import RightArrowIcon from "@images/svg/icon-right-arrow_white_solid.svg"



const Example = () => {
    const [isView, setIsView] = useState(false);
    const handleClick = () => {
        setIsView(!isView);
    };
  
    return (
        <>
            <div class={`menu-trigger ${isView ? 'active' : null}`} onClick={handleClick}>
                <button class="menu-trigger__btn">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
            <nav class={`sp-menu ${isView ? 'active' : null}`}>
                <div class="sp-menu__container">
                    <ul>
                    <li><Link onClick={handleClick} to="/about">ABOUT US<span>私たちについて</span></Link></li>
                    <li><Link onClick={handleClick} to="/#service">SERVICE<span>サービス内容</span></Link></li>
                    <li><Link onClick={handleClick} to="/teachers">TEACHERS<span>講師紹介</span></Link></li>
                    <li><Link onClick={handleClick} to="/#price">PRICE<span>料金表</span></Link></li>
                    <li><Link onClick={handleClick} to="/news">NEWS<span>お知らせ</span></Link></li>
                    <li><Link onClick={handleClick} target="_blank" to="https://docs.google.com/forms/d/e/1FAIpQLSfXw6lYG22uuqXsiM8ARzbtxIm76nfwe3b7SIHj0gSeblCEiA/viewform">RECRUIT<span>講師募集</span></Link></li>
                    </ul>
                    {/* <p class="download-btn download-btn--sp"><a download href="/KnockLearn.pdf">資料ダウンロード<RightArrowIcon /></a></p> */}
                    <p class="download-btn download-btn--sp"><a download href="https://docs.google.com/presentation/d/1avTigpT8XO_OsvnN-dUXQYDMQXRwXNhnQQRcdQvhyXo/edit#slide=id.oYnTEoAd">資料ダウンロード<RightArrowIcon /></a></p>
                    <p class="btn btn--primary"><a href="/contact">申し込みはこちら<RightArrowIcon /></a></p>
                </div>
            </nav>
        </>
    );
  }
  
  export default Example;