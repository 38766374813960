import React from "react"
import Header from "@src/components/header"
import Footer from "@src/components/footer"
import Image from "@components/image"

const Layout = (props) => {
  
  return (
    <>
      <Header />
        <div class="bg-column"><Image filename="bg-column_solid.png" alt="" /></div>
        <main>
          {props.children}
        </main>
      <Footer />
      </>
  )
}
export default Layout;