import React from "react"
import { Link } from "gatsby"
import Logo from "@images/svg/logo_simple.svg"
import MailIcon from "@images/svg/icon-mail_solid_white.svg"
import MenuTrigger from "@components/menu-trigger";
import '@styles/global/header.scss'

export default () => (
  <header>
    <nav class="header-nav">
      <Link className="logo" to="/"><Logo width={false} height={false} /></Link>
      <div class="wrapper">
        <ul class="header-nav__list">
          <li><Link to="/about">ABOUT US<span>私たちについて</span></Link></li>
          <li><Link to="/#service">SERVICE<span>サービス内容</span></Link></li>
          <li><Link to="/teachers">TEACHERS<span>講師紹介</span></Link></li>
          <li><Link to="/#price">PRICE<span>料金表</span></Link></li>
          <li><Link to="/news">NEWS<span>お知らせ</span></Link></li>
          <li><Link target="_blank" to="https://docs.google.com/forms/d/e/1FAIpQLSfXw6lYG22uuqXsiM8ARzbtxIm76nfwe3b7SIHj0gSeblCEiA/viewform">RECRUIT<span>講師募集</span></Link></li>
        </ul>
        <a className="download-btn" download href="/KnockLearn.pdf"><span>資料<br/>ダウンロード</span></a>
        <Link className="contact-btn" to="/contact"><MailIcon width={false} height={false}/><span>お申し込み</span></Link>
        <MenuTrigger />
      </div>
    </nav>
  </header>
)