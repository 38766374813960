import React from "react"
import { Link } from "gatsby"
import '@styles/components/footer.scss'
import Logo from "@images/svg/logo_simple.svg"

export default () => (
  <footer class="footer">
    <div class="footer__container">
        <div class="left-col">
          <Logo className="logo" width={false} height={false}/>
          <p>株式会社 ノックラーン</p>
          <address><span>〒143-0011</span> 東京都大田区大森本町1丁目5番地1号 2階</address>
          <p class="copyright"><small>©︎ KnockLearn 2022.</small></p>
        </div>
        <nav class="right-col">
          <ul>
            <li><Link to="/">ホーム</Link></li>
            <li><Link to="/about">私たちについて</Link></li>
            <li><Link to="/#service">サービス内容</Link></li>
            <li><Link to="/teachers">講師紹介</Link></li>
          </ul>
          <ul>
            <li><Link to="/#price">料金表</Link></li>
            <li><Link to="/news">お知らせ</Link></li>
            <li><Link target="_blank" to="https://docs.google.com/forms/d/e/1FAIpQLSfXw6lYG22uuqXsiM8ARzbtxIm76nfwe3b7SIHj0gSeblCEiA/viewform">採用情報</Link></li>
            <li><Link to="/contact">お申し込み</Link></li>
            <li><Link to="/privacy">プライバシーポリシー</Link></li>
          </ul>
          {/* <ul> */}
            {/* <li><AniLink to="/">よくある質問</AniLink></li> */}
            
          {/* </ul> */}
        </nav>
    </div>
  </footer>
)